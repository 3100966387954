import * as s from "../styles/globalStyles";
import { Grid, Cell } from "styled-css-grid";

export const AllRarityGrid = () => (
  <Grid 
  columns={2} minRowHeight="50px"
  // alignContent="space-evenly"
  // justifyContent="space-evenly"
  >
    {/* Background */}
    <Cell left={1} top={1} width={2}><s.RarityTitle>Backgounds</s.RarityTitle></Cell>
    <Cell left={1} top={2} center middle><s.Rarity1> Black 8% </s.Rarity1></Cell>
    <Cell left={2} top={2} center middle><s.Rarity1> Orange 8% </s.Rarity1></Cell>
    <Cell left={1} top={3} center middle><s.Rarity2> Light Blue 4% </s.Rarity2></Cell>
    <Cell left={2} top={3} center middle><s.Rarity3> Baby Blue 3% </s.Rarity3></Cell>
    <Cell left={1} top={4} center middle><s.Rarity1> Blue 8% </s.Rarity1></Cell>
    <Cell left={2} top={4} center middle><s.Rarity1> Pink 7% </s.Rarity1></Cell>
    <Cell left={1} top={5} center middle><s.Rarity2> Light Green 4% </s.Rarity2></Cell>
    <Cell left={2} top={5} center middle><s.Rarity3> Rainbow 1% </s.Rarity3></Cell>
    <Cell left={1} top={6} center middle><s.Rarity1> Gray 8% </s.Rarity1></Cell>
    <Cell left={2} top={6} center middle><s.Rarity1> Purple 7% </s.Rarity1></Cell>
    <Cell left={1} top={7} center middle><s.Rarity2> Light Orange 4% </s.Rarity2></Cell>
    <Cell left={2} top={7} center middle><s.Rarity1> Green 8% </s.Rarity1></Cell>
    <Cell left={1} top={8} center middle><s.Rarity1> Red 8% </s.Rarity1></Cell>
    <Cell left={2} top={8} center middle><s.Rarity2> Light Purple 4% </s.Rarity2></Cell>
    <Cell left={1} top={9} center middle><s.Rarity1> Magenta 7% </s.Rarity1></Cell>
    <Cell left={2} top={9} center middle><s.Rarity1> Yellow 7% </s.Rarity1></Cell>
    <Cell left={1} top={10} center middle><s.Rarity2> Light Yellow 4% </s.Rarity2></Cell>
    {/* Fur */}
    <Cell left={1} top={11} width={2}><s.RarityTitle>Fur</s.RarityTitle></Cell>
    <Cell left={1} top={12} center middle><s.Rarity2> Aubergine 2% </s.Rarity2></Cell>
    <Cell left={2} top={12} center middle><s.Rarity2> Black 2% </s.Rarity2></Cell>
    <Cell left={1} top={13} center middle><s.Rarity1> BlueBaby 3% </s.Rarity1></Cell>
    <Cell left={2} top={13} center middle><s.Rarity1> Blueberry 3% </s.Rarity1></Cell>
    <Cell left={1} top={14} center middle><s.Rarity2> British Shorthair 2% </s.Rarity2></Cell>
    <Cell left={2} top={14} center middle><s.Rarity2> Calico 2% </s.Rarity2></Cell>
    <Cell left={1} top={15} center middle><s.Rarity1> Chili 3% </s.Rarity1></Cell>
    <Cell left={2} top={15} center middle><s.Rarity1> Clay 4% </s.Rarity1></Cell>
    <Cell left={1} top={16} center middle><s.Rarity2> Cream 2% </s.Rarity2></Cell>
    <Cell left={2} top={16} center middle><s.Rarity3> Day Chief 0.67% </s.Rarity3></Cell>
    <Cell left={1} top={17} center middle><s.Rarity2> Day Lavender 2% </s.Rarity2></Cell>
    <Cell left={2} top={17} center middle><s.Rarity2> Day Teal 2% </s.Rarity2></Cell>
    <Cell left={1} top={18} center middle><s.Rarity1> Dijon 3% </s.Rarity1></Cell>
    <Cell left={2} top={18} center middle><s.Rarity1> Grapefruit 4% </s.Rarity1></Cell>
    <Cell left={1} top={19} center middle><s.Rarity1> Grass 4% </s.Rarity1></Cell>
    <Cell left={2} top={19} center middle><s.Rarity2> Gray Tabby 2% </s.Rarity2></Cell>
    <Cell left={1} top={20} center middle><s.Rarity2> Ivory 2% </s.Rarity2></Cell>
    <Cell left={2} top={20} center middle><s.Rarity1> Lasagna 4% </s.Rarity1></Cell>
    <Cell left={1} top={21} center middle><s.Rarity1> MorePinky 3% </s.Rarity1></Cell>
    <Cell left={2} top={21} center middle><s.Rarity1> Mud 3% </s.Rarity1></Cell>
    <Cell left={1} top={22} center middle><s.Rarity1> Mustard 3% </s.Rarity1></Cell>
    <Cell left={2} top={22} center middle><s.Rarity3> Night Chief 0.69% </s.Rarity3></Cell>
    <Cell left={1} top={23} center middle><s.Rarity2> Night Lavender 2% </s.Rarity2></Cell>
    <Cell left={2} top={23} center middle><s.Rarity2> Night Teal 2% </s.Rarity2></Cell>
    <Cell left={1} top={24} center middle><s.Rarity2> Noon Lavender 2% </s.Rarity2></Cell>
    <Cell left={2} top={24} center middle><s.Rarity1> Orange Tabby 4% </s.Rarity1></Cell>
    <Cell left={1} top={25} center middle><s.Rarity1> Pinky 3% </s.Rarity1></Cell>
    <Cell left={2} top={25} center middle><s.Rarity1> Pistachio 3% </s.Rarity1></Cell>
    <Cell left={1} top={26} center middle><s.Rarity2> PumpkinSpice 2% </s.Rarity2></Cell>
    <Cell left={2} top={26} center middle><s.Rarity2> Ragdoll 2% </s.Rarity2></Cell>
    <Cell left={1} top={27} center middle><s.Rarity2> Red Ears 2% </s.Rarity2></Cell>
    <Cell left={2} top={27} center middle><s.Rarity1> Rose 3% </s.Rarity1></Cell>
    <Cell left={1} top={28} center middle><s.Rarity2> Siamese 2% </s.Rarity2></Cell>
    <Cell left={2} top={28} center middle><s.Rarity1> Sky 4% </s.Rarity1></Cell>
    <Cell left={1} top={29} center middle><s.Rarity1> Tan 3% </s.Rarity1></Cell>
    <Cell left={2} top={29} center middle><s.Rarity1> Vino 4% </s.Rarity1></Cell>
    <Cell left={1} top={30} center middle><s.Rarity1> Water 4% </s.Rarity1></Cell>
    {/* Eyes Background/Colour */}
    <Cell left={1} top={31} width={2}><s.RarityTitle>Eyes Backgound/Colour</s.RarityTitle></Cell>
    <Cell left={1} top={32} center middle><s.Rarity1> Blue Blue 12% </s.Rarity1></Cell>
    <Cell left={2} top={32} center middle><s.Rarity2> Blue Green 7% </s.Rarity2></Cell>
    <Cell left={1} top={33} center middle><s.Rarity2> Blue Orange 6% </s.Rarity2></Cell>
    <Cell left={2} top={33} center middle><s.Rarity1> Green Green 12% </s.Rarity1></Cell>
    <Cell left={1} top={34} center middle><s.Rarity2> Green Pink 7% </s.Rarity2></Cell>
    <Cell left={2} top={34} center middle><s.Rarity1> Orange Orange 12% </s.Rarity1></Cell>
    <Cell left={1} top={35} center middle><s.Rarity2> Orange Yellow 7% </s.Rarity2></Cell>
    <Cell left={2} top={35} center middle><s.Rarity1> Pink Pink 13% </s.Rarity1></Cell>
    <Cell left={1} top={36} center middle><s.Rarity2> Pink Yellow 6% </s.Rarity2></Cell>
    <Cell left={2} top={36} center middle><s.Rarity2> Yellow Green 6% </s.Rarity2></Cell>
    <Cell left={1} top={37} center middle><s.Rarity1> Yellow Yellow 12% </s.Rarity1></Cell>
    {/* Eyes Rarity */}
    <Cell left={1} top={38} width={2}><s.RarityTitle>Eyes</s.RarityTitle></Cell>
    <Cell left={1} top={39} center middle><s.Rarity1> Black Thick Curved Eyes 001 11% </s.Rarity1></Cell>
    <Cell left={2} top={39} center middle><s.Rarity1> Black Thick Curved Eyes 002 10% </s.Rarity1></Cell>
    <Cell left={1} top={40} center middle><s.Rarity1> Black Thick Dot Eyes 14% </s.Rarity1></Cell>
    <Cell left={2} top={40} center middle><s.Rarity1> Black Thick Full Eyes 001 14% </s.Rarity1></Cell>
    <Cell left={1} top={41} center middle><s.Rarity1> Black Thick Full Eyes 002 13% </s.Rarity1></Cell>
    <Cell left={2} top={41} center middle><s.Rarity1> Black Thick Full Eyes 003 14% </s.Rarity1></Cell>
    <Cell left={1} top={42} center middle><s.Rarity2> Black Thick X Eyes 7% </s.Rarity2></Cell>
    <Cell left={2} top={42} center middle><s.Rarity2> Black Thin Dot Eyes 7% </s.Rarity2></Cell>
    <Cell left={1} top={43} center middle><s.Rarity2> Black Thin X Eyes 7% </s.Rarity2></Cell>
    <Cell left={2} top={43} center middle><s.Rarity3> Golden Thick X Eyes 1.63% </s.Rarity3></Cell>
    <Cell left={1} top={44} center middle><s.Rarity3> Red Thin Dot Eyes 2% </s.Rarity3></Cell>
    {/* Shirt Rarity */}
    <Cell left={1} top={45} width={2}><s.RarityTitle>Shirts</s.RarityTitle></Cell>
    <Cell left={1} top={46} center middle><s.Rarity1> Blue Flannel Shirt 8% </s.Rarity1></Cell>
    <Cell left={2} top={46} center middle><s.Rarity1> Blue Overalls 6% </s.Rarity1></Cell>
    <Cell left={1} top={47} center middle><s.Rarity1> Blue Shirt 7% </s.Rarity1></Cell>
    <Cell left={2} top={47} center middle><s.Rarity1> Blue Sleeveless Shirt 6% </s.Rarity1></Cell>
    <Cell left={1} top={48} center middle><s.Rarity1> Blue Stripe Sweater 7% </s.Rarity1></Cell>
    <Cell left={2} top={48} center middle><s.Rarity2> Blue Sweater 4% </s.Rarity2></Cell>
    <Cell left={1} top={49} center middle><s.Rarity1> Dirty Shirt 6% </s.Rarity1></Cell>
    <Cell left={2} top={49} center middle><s.Rarity2> Green Stripe Shirt 4% </s.Rarity2></Cell>
    <Cell left={1} top={50} center middle><s.Rarity1> None 14% </s.Rarity1></Cell>
    <Cell left={2} top={50} center middle><s.Rarity3> Rainbow Shirt 2% </s.Rarity3></Cell>
    <Cell left={1} top={51} center middle><s.Rarity1> Red Flannel Shirt 8% </s.Rarity1></Cell>
    <Cell left={2} top={51} center middle><s.Rarity1> Red Shirt 8% </s.Rarity1></Cell>
    <Cell left={1} top={52} center middle><s.Rarity1> Red and White Sleeveless Shirt 6% </s.Rarity1></Cell>
    <Cell left={2} top={52} center middle><s.Rarity3> Red and White Stripe Shirt 2% </s.Rarity3></Cell>
    <Cell left={1} top={53} center middle><s.Rarity2> Squiggly Shirt 4% </s.Rarity2></Cell>
    <Cell left={2} top={53} center middle><s.Rarity3> Sunshine Shirt 2% </s.Rarity3></Cell>
    <Cell left={1} top={54} center middle><s.Rarity2> White Collar Shirt 4% </s.Rarity2></Cell>
    <Cell left={2} top={54} center middle><s.Rarity2> White Shirt 4% </s.Rarity2></Cell>
    {/* Glasses Rarity */}
    <Cell left={1} top={55} width={2}><s.RarityTitle>Glasses</s.RarityTitle></Cell>
    <Cell left={1} top={56} center middle><s.Rarity3> Black Eye Patch 4% </s.Rarity3></Cell>
    <Cell left={2} top={56} center middle><s.Rarity1> Clear Glasses 23% </s.Rarity1></Cell>
    <Cell left={1} top={57} center middle><s.Rarity2> Green and Purple Glasse 15% </s.Rarity2></Cell>
    <Cell left={2} top={57} center middle><s.Rarity1> None 28% </s.Rarity1></Cell>
    <Cell left={1} top={58} center middle><s.Rarity2> Purple and Orange Glasses 15% </s.Rarity2></Cell>
    <Cell left={2} top={58} center middle><s.Rarity2> Yellow and Green Glasses 15% </s.Rarity2></Cell>
    {/* Hats Rarity */}
    <Cell left={1} top={59} width={2}><s.RarityTitle>Hats</s.RarityTitle></Cell>
    <Cell left={1} top={60} center middle><s.Rarity3> Bitcoin 1.27% </s.Rarity3></Cell>
    <Cell left={2} top={60} center middle><s.Rarity1> Blue and White Hat 13% </s.Rarity1></Cell>
    <Cell left={1} top={61} center middle><s.Rarity3> Cardano 2.49% </s.Rarity3></Cell>
    <Cell left={2} top={61} center middle><s.Rarity2> Chicken Drumstick 5% </s.Rarity2></Cell>
    <Cell left={1} top={62} center middle><s.Rarity1> Construction Helmet 9% </s.Rarity1></Cell>
    <Cell left={2} top={62} center middle><s.Rarity3> Ethereum 1.17% </s.Rarity3></Cell>
    <Cell left={1} top={63} center middle><s.Rarity2> Flower 6% </s.Rarity2></Cell>
    <Cell left={2} top={63} center middle><s.Rarity1> Green Hat 12% </s.Rarity1></Cell>
    <Cell left={1} top={64} center middle><s.Rarity2> Halo 3.55 </s.Rarity2></Cell>
    <Cell left={2} top={64} center middle><s.Rarity2> Ice Cream and Sprinkles 6.72% </s.Rarity2></Cell>
    <Cell left={1} top={65} center middle><s.Rarity1> None 18% </s.Rarity1></Cell>
    <Cell left={2} top={65} center middle><s.Rarity2> Poop 6% </s.Rarity2></Cell>
    <Cell left={1} top={66} center middle><s.Rarity3> Rainbow Unicorn 1.25% </s.Rarity3></Cell>
    <Cell left={2} top={66} center middle><s.Rarity2> Red and White Hat 4.6% </s.Rarity2></Cell>
    <Cell left={1} top={67} center middle><s.Rarity2> Shiba Inu 3.37% </s.Rarity2></Cell>
    <Cell left={2} top={67} center middle><s.Rarity3> Solana 2.52% </s.Rarity3></Cell>
    <Cell left={1} top={68} center middle><s.Rarity2> Unicorn 3.47% </s.Rarity2></Cell>
    {/* Jewelry Rarity */}
    <Cell left={1} top={69} width={2}><s.RarityTitle>Jewelry</s.RarityTitle></Cell>
    <Cell left={1} top={70} center middle><s.Rarity2> Gold Chain 13% </s.Rarity2></Cell>
    <Cell left={2} top={70} center middle><s.Rarity2> Gold Ear Ring 12% </s.Rarity2></Cell>
    <Cell left={1} top={71} center middle><s.Rarity2> Gold Tail Ring 12% </s.Rarity2></Cell>
    <Cell left={2} top={71} center middle><s.Rarity1> None 62% </s.Rarity1></Cell>
    {/* Ties Rarity */}
    <Cell left={1} top={72} width={2}><s.RarityTitle>Ties</s.RarityTitle></Cell>
    <Cell left={1} top={73} center middle><s.Rarity3> Black Bowtie 3% </s.Rarity3></Cell>
    <Cell left={2} top={73} center middle><s.Rarity2> Blue Stripe Bowtie 12% </s.Rarity2></Cell>
    <Cell left={1} top={74} center middle><s.Rarity1> None 23% </s.Rarity1></Cell>
    <Cell left={2} top={74} center middle><s.Rarity2> Orange Stripe Bowtie 12% </s.Rarity2></Cell>
    <Cell left={1} top={75} center middle><s.Rarity2> Polkadot Bowtie 13% </s.Rarity2></Cell>
    <Cell left={2} top={75} center middle><s.Rarity1> Red Bowtie 18% </s.Rarity1></Cell>
    <Cell left={1} top={76} center middle><s.Rarity1> Yellow Bowtie 18% </s.Rarity1></Cell>
    {/* Mouth Accessories Rarity */}
    <Cell left={1} top={77} width={2}><s.RarityTitle>Mouth Accessories</s.RarityTitle></Cell>
    <Cell left={1} top={78} center middle><s.Rarity2> Blue Pacifier 13% </s.Rarity2></Cell>
    <Cell left={2} top={78} center middle><s.Rarity3> Drool 3% </s.Rarity3></Cell>
    <Cell left={1} top={79} center middle><s.Rarity2> Fishbone 13% </s.Rarity2></Cell>
    <Cell left={2} top={79} center middle><s.Rarity1> None 24% </s.Rarity1></Cell>
    <Cell left={1} top={80} center middle><s.Rarity2> Pink Pacifier 13% </s.Rarity2></Cell>
    <Cell left={2} top={80} center middle><s.Rarity1> Tongue 19% </s.Rarity1></Cell>
    <Cell left={1} top={81} center middle><s.Rarity3> Weed 3% </s.Rarity3></Cell>
    <Cell left={2} top={81} center middle><s.Rarity2> Wheat 12% </s.Rarity2></Cell>
    {/* Misc Rarity */}
    <Cell left={1} top={82} width={2}><s.RarityTitle>Misc</s.RarityTitle></Cell>
    <Cell left={1} top={83} center middle><s.Rarity2> Bandage 20% </s.Rarity2></Cell>
    <Cell left={2} top={83} center middle><s.Rarity3> Droplet 10% </s.Rarity3></Cell>
    <Cell left={1} top={84} center middle><s.Rarity1> None 70% </s.Rarity1></Cell>
  </Grid>
);